/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import axiosInstance from "axios";

import Footer from "components/Footer";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

// NewProduct page components
const validationSchema = yup.object({
  employee_id: yup.string("Enter a valid employee id").required("Employee id is required"),
  name: yup.string("Enter a valid full name").required("Full name is required"),
  email: yup
    .string("Enter a valid email")
    .required("Email is required")
    .email("Enter a valid email"),
  mobile_number: yup.number("Enter  mobile number").required("Mobile number is required"),
  office_phone_extension: yup
    .number("Enter office phone extension")
    .required("office phone extension is required"),
  role: yup.string().required("Choose a role"),
});
function UsersAddScreen() {
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");
  // const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      employee_id: "",
      name: "",
      email: "",
      role: "",
      mobile_number: "",
      office_phone_extension: "",
      password: "ccis2021",
      re_password: "ccis2021",
    },

    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      /* eslint-disable camelcase */
      const {
        employee_id,
        name,
        email,
        role,
        mobile_number,
        office_phone_extension,
        password,
        re_password,
      } = values;
      /* eslint-enable camelcase */
      const addUser = async () => {
        try {
          // setLoading(true);

          const { data } = await axiosInstance.post(
            `/api/auth/users/` /* eslint-disable camelcase */,
            {
              employee_id,
              name,
              email,
              role,
              mobile_number,
              office_phone_extension,
              password,
              re_password,
              is_active: false,
            }
          );

          console.log(data);
          navigate("/users");
          // setLoading(false);
          // setSuccess(true);
        } catch (err) {
          console.log("error:  ", err);
          // setError("Something went wrong!");
          // setLoading(false);
        }
      };

      addUser();
    },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h4" fontWeight="medium" align="center">
                Add User
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <FormikProvider value={formik}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            component="form"
            role="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid item xs={12} lg={8}>
              <Card>
                <MDBox p={3}>
                  <MDBox mt={1}>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={12} sm={6}>
                        <Tooltip title="This specifies the name of the user." placement="top">
                          <div>
                            <Field
                              as={MDInput}
                              type="text"
                              label="Full Name"
                              fullWidth
                              name="name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              error={formik.touched.name && Boolean(formik.errors.name)}
                            />
                          </div>
                        </Tooltip>
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="name" />
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title="The email of the user. The email address should belong to the company
domain."
                          placement="top"
                        >
                          <div>
                            <Field
                              as={MDInput}
                              type="email"
                              label="Email Address"
                              fullWidth
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                            />
                          </div>
                        </Tooltip>
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="email" />
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Tooltip
                          title="It specifies the official ID of the employee in the company."
                          placement="top"
                        >
                          <div>
                            <Field
                              as={MDInput}
                              type="text"
                              label="Employee Id"
                              fullWidth
                              name="employee_id"
                              value={formik.values.employee_id}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.employee_id && Boolean(formik.errors.employee_id)
                              }
                            />
                          </div>
                        </Tooltip>
                        <MDBox mt={0}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="employee_id" />
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Tooltip
                          title="It represents the mobile phone number of the user (employee)."
                          placement="top"
                        >
                          <div>
                            <Field
                              as={MDInput}
                              type="text"
                              label="Mobile Number"
                              fullWidth
                              name="mobile_number"
                              value={formik.values.mobile_number}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.mobile_number && Boolean(formik.errors.mobile_number)
                              }
                            />
                          </div>
                        </Tooltip>
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="mobile_number" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Tooltip
                          title="It represents the office phone number of the user (employee)"
                          placement="top"
                        >
                          <div>
                            <Field
                              as={MDInput}
                              type="text"
                              label="Office Phone Number"
                              fullWidth
                              name="office_phone_extension"
                              value={formik.values.office_phone_extension}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.office_phone_extension &&
                                Boolean(formik.errors.office_phone_extension)
                              }
                            />
                          </div>
                        </Tooltip>
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="office_phone_extension" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDBox mb={2}>
                          <Tooltip title="It specifies the role of the user." placement="top">
                            <div>
                              <Autocomplete
                                options={["ADMIN", "MANAGER", "OPERATOR", "COFFEE_OPERATOR"]}
                                renderInput={(params) => (
                                  <MDInput
                                    {...params}
                                    label="User Role"
                                    variant="outlined"
                                    name="role"
                                    error={formik.touched.role && Boolean(formik.errors.role)}
                                  />
                                )}
                                fullWidth
                                value={formik.values.role}
                                onChange={(e, newValue) => formik.setFieldValue("role", newValue)}
                                onBlur={() => formik.setTouched({ role: true })}
                              />
                            </div>
                          </Tooltip>
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              <ErrorMessage name="role" />
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <MDBox display="flex" justifyContent="flex-end">
                          <MDButton variant="gradient" color="info" type="submit">
                            save
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </FormikProvider>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UsersAddScreen;
