/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import axios from "config/axios";
import Footer from "components/Footer";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
// formik components
import { ErrorMessage, FormikProvider, Field, useFormik } from "formik";
import { useSelector } from "react-redux";
// NewProduct page components
import * as yup from "yup";

const validationSchema = yup.object({
  current_password: yup
    .string("Enter your current password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  new_password: yup
    .string("Enter your new password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  re_new_password: yup
    .string("Retype your new password")
    .required("Required")
    .oneOf([yup.ref("new_password"), null], "Passwords don't match!"),
});
function EditPasswordScreen() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      re_new_password: "",
    },

    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      /* eslint-disable  */
      const { current_password, new_password, re_new_password } = values;
      const resetPassword = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo.access}`,
            },
          };

          setLoading(true);

          const { data } = await axios.post(
            `/api/auth/users/set_password/`,
            { current_password, new_password, re_new_password },
            config
          );

          console.log(data);
          setLoading(false);
          setSuccess(true);
          formik.resetForm();
        } catch (err) {
          console.log("error", err);
          setError(
            err.response && err.response.data.message
              ? err.response.data.message
              : "Something went wrong!"
          );
          setSuccess(false);
          // notify(
          //   err.response && err.response.data.message
          //     ? err.response.data.message
          //     : err.message,
          //   'danger'
          // );
          setLoading(false);
        }
      };

      resetPassword();
      /* eslint-enable  */
    },
  });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h4" fontWeight="medium" align="center">
                Update Password
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} justifyContent="center">
          {error && (
            <Grid container justifyContent="center">
              <MDBox m={0.75} mb={5}>
                <Alert severity="error" sx={{ width: "100%" }}>
                  Something went wrong!
                </Alert>
              </MDBox>
            </Grid>
          )}
          {success && (
            <Grid container justifyContent="center">
              <MDBox m={0.75} mb={5}>
                <Alert severity="success" sx={{ width: "100%" }}>
                  Password has been updated successfully!
                </Alert>
              </MDBox>
            </Grid>
          )}
          <Grid item xs={12} lg={4}>
            <Card>
              <MDBox p={3}>
                <FormikProvider value={formik}>
                  <MDBox mt={1} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid item xs={12} sm={8}>
                        <Field
                          as={MDInput}
                          type="password"
                          label="Current Password"
                          name="current_password"
                          fullWidth
                          value={formik.values.current_password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.current_password &&
                            Boolean(formik.errors.current_password)
                          }
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="current_password" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Field
                          as={MDInput}
                          type="password"
                          label="New Password"
                          name="new_password"
                          fullWidth
                          value={formik.values.new_password}
                          onChange={formik.handleChange}
                          error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="new_password" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Field
                          as={MDInput}
                          type="password"
                          label="Confirm Password"
                          name="re_new_password"
                          fullWidth
                          value={formik.values.re_new_password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.re_new_password && Boolean(formik.errors.re_new_password)
                          }
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="re_new_password" />
                          </MDTypography>
                        </MDBox>
                      </Grid>

                      <Grid item xs={12} lg={12}>
                        <MDBox display="flex" justifyContent="flex-end">
                          <MDButton
                            variant="gradient"
                            color="info"
                            type="submit"
                            disabled={loading}
                          >
                            save
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </FormikProvider>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditPasswordScreen;
