/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";
// import DefaultImage from "assets/images/default-avatar.png";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
// import MDButton from "components/MDComponents/MDButton";
import axiosInstance from "config/axios";
import Footer from "components/Footer";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import ActionsCell from "./components/ActionsCell";
// import CustomerCell from "./components/CustomerCell";
import DefaultCell from "./components/DefaultCell";
// Cells
import IdCell from "./components/IdCell";
import StatusCell from "./components/StatusCell";

/* eslint-disable react/prop-types */
const columns = [
  { Header: "ID", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
  // {
  //   Header: "User",
  //   accessor: "user",
  //   Cell: ({ value: [name, data] }) => (
  //     <CustomerCell image={data.image || DefaultImage} color={data.color || "dark"} name={name} />
  //   ),
  // },
  // { Header: "Gender", accessor: "gender", Cell: ({ value }) => <DefaultCell value={value} /> },
  { Header: "Email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
  {
    Header: "Feedback",
    accessor: "feedback",
    Cell: ({ value }) => <DefaultCell value={value} style={{}} />,
  },

  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value === "Checked") {
        status = <StatusCell icon="done" color="success" status="Checked" />;
      } else if (value === "New") {
        status = <StatusCell icon="pending" color="dark" status="New" />;
      } else {
        status = <StatusCell icon="close" color="error" status="Canceled" />;
      }

      return status;
    },
  },
  // { Header: "Mail To", accessor: "mailto", Cell: ({ value }) => <DefaultCell value={value} /> },
  {
    Header: "Actions",
    accessor: "action",
    Cell: ({ value }) => <ActionsCell value={value} />,
  },
];
/* eslint-enable react/prop-types */

function FeedbackList() {
  // const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [table, setTable] = useState({
    columns,
    rows: [],
  });
  const handleDelete = async (id) => {
    const { res } = await axiosInstance.delete(`api/list_feedback/${id}/`);
    console.log(res);
    setTable((prevTable) => ({
      ...table,
      rows: prevTable.rows.filter((user) => user.id !== id),
    }));
  };
  const handleEdit = async (email) => {
    window.location = `mailto:${email}`;
  };
  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await axiosInstance.get("api/list_feedback/");
        console.log("data: ", data);
        setTable({
          ...table,
          rows: data.results
            ? data.results.map((prop) => ({
                id: prop.id,
                email: prop.email,
                feedback: prop.feedback,
                status: prop.is_active ? "New" : prop.status,
                action: {
                  value: "editDelete",
                  id: prop.id,
                  handleDelete,
                  handleEdit,
                  email: prop.email,
                  canDelete: prop.id !== userInfo.id,
                },
              }))
            : [],
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };

    getUsers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="info" onClick={() => navigate("/users/create")}>
            new user
          </MDButton> */}
        </MDBox>
        <Card>
          <DataTable table={table} entriesPerPage={10} canSearch />
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default FeedbackList;
