/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDBadgeDot from "components/MDComponents/MDBadgeDot";
import MDButton from "components/MDComponents/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
// Data
// import defaultLineChartData from "screens/Dashboard/data/defaultLineChartData";
//  dates imports

// axios
// import axios from "axios";
import axiosinstance from "config/axios";

function Dashboard() {
  // // DefaultStatisticsCard state for the dropdown value
  // const [revenueDropdownValue, setRevenueDropdownValue] = useState("Today");

  // // DefaultStatisticsCard state for the dropdown action
  // const [revenueDropdown, setRevenueDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action

  // const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  // const closeRevenueDropdown = ({ currentTarget }) => {
  //   setRevenueDropdown(null);
  //   setRevenueDropdownValue(currentTarget.innerText);
  // };

  // const [showMore, setShowMore] = useState(false);

  // //  To have more information on click
  // const toggleShowMore = () => {
  //   setShowMore(!showMore);
  // };

  // const renderModalContent = () => (
  //   <div className="modal-content">
  //     <p>more of specified content will be shown here.. </p>
  //   </div>
  // );

  //  Chart data
  const [chartdata, setChartdata] = useState({
    labels: [],
    datasets: [
      {
        label: "Completed",
        color: "success",
        data: [],
      },
    ],
  });

  const [data, setData] = useState({});
  const [timeFrame, setTimeFrame] = useState("daily");

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosinstance.get("/api/umrah/data/");
      console.log("the data", response.data);
      setData(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data[timeFrame]) {
      const keys = Object.keys(data[timeFrame]);
      const values = Object.values(data[timeFrame]);
      setChartdata({
        ...chartdata,
        labels: keys,
        datasets: [
          {
            label: "Completed",
            data: values,
          },
        ],
      });
    }
  }, [data, timeFrame]);

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };
  //  Today's Umrahs
  const [todayUmrah, settodayUmrah] = useState([]);

  useEffect(() => {
    async function fetchtodayUmrah() {
      try {
        const response = await axiosinstance.get("/api/umrah/today/");
        settodayUmrah(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchtodayUmrah();
  }, []);

  //  Count Umrahs
  const [totalUmrah, settotalUmrah] = useState([]);

  useEffect(() => {
    async function fetchtotalUmrah() {
      try {
        const response = await axiosinstance.get("/api/umrah/count/");
        settotalUmrah(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchtotalUmrah();
  }, []);

  //  Count Feedbacks
  const [totalfeedback, settotalfeedback] = useState([]);

  useEffect(() => {
    async function fetchtotalfeedback() {
      try {
        const response = await axiosinstance.get("/api/feedback/count/");
        settotalfeedback(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchtotalfeedback();
  }, []);

  //  Count New Feedbacks
  const [newfeedback, setnewfeedback] = useState([]);

  useEffect(() => {
    async function fetchnewfeedback() {
      try {
        const response = await axiosinstance.get("/api/new/feedback/count/");
        setnewfeedback(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchnewfeedback();
  }, []);

  //   Getting the average duration of all Umrahs by gender
  const [durationavgbygender, setDurationAvgByGender] = useState([]);

  useEffect(() => {
    async function fetchdurationavgbygender() {
      try {
        const response = await axiosinstance.get("/api/umrah/average/duration/by/gender/");
        setDurationAvgByGender(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchdurationavgbygender();
  }, []);

  //   Getting the average duration of all Umrahs by nationality
  const [durationavgbynationality, setDurationAvgByNationality] = useState([]);

  useEffect(() => {
    async function fetchDurationAvgByNationality() {
      try {
        const response = await axiosinstance.get("/api/umrah/average/duration/by/nationality/");
        setDurationAvgByNationality(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchDurationAvgByNationality();
  }, []);
  //  Getting the top shortest and longest duration of an Umrah
  /* eslint-disable react/no-array-index-key */
  // const [shortestDurations, setShortestDurations] = useState([]);
  // const [longestDurations, setLongestDurations] = useState([]);

  // useEffect(() => {
  //   // Fetch the top 5 shortest and longest durations from the API
  //   const fetchTopDurations = async () => {
  //     try {
  //       const response = await axiosinstance.get("/api/top/umrah/duration/");
  //       setShortestDurations(response.data.shortest_umrah);
  //       setLongestDurations(response.data.longest_umrah);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchTopDurations();
  // }, []);
  //  getting the percentage of each gender
  const [genderpercentage, setgenderpercentage] = useState([]);

  useEffect(() => {
    async function fetchgenderpercentage() {
      try {
        const response = await axiosinstance.get("/api/gender/percentage/");
        setgenderpercentage(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchgenderpercentage();
  }, []);

  //  getting the top 5 nationalities
  const [nationalities, setNationalities] = useState([]);

  useEffect(() => {
    async function fetchNationalities() {
      try {
        const response = await axiosinstance.get("/api/top/nationalities/");
        setNationalities(response.data.nationalities);
      } catch (error) {
        console.error(error);
      }
    }
    fetchNationalities();
  }, []);

  //  getting the repeated umrah percentage
  // const [repeatedUmrah, setRepeatedUmrah] = useState([]);

  // useEffect(() => {
  //   async function fetchrepeatedUmrah() {
  //     try {
  //       const response = await axiosinstance.get("/api/repeated/umrah/");
  //       setRepeatedUmrah(response.data.repeated_percentage);
  //       // console.log(response.data.repeated_percentage);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   fetchrepeatedUmrah();
  // }, []);

  // Getting the average time between umrahs
  // const [durationData, setDurationData] = useState([]);

  // useEffect(() => {
  //   const fetchDurationData = async () => {
  //     try {
  //       const response = await axiosinstance.get("/api/apiaverage/time/between/umrahs");
  //       setDurationData(response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchDurationData();
  // }, []);

  // Dropdown menu template for the DefaultStatisticsCard
  // const renderMenu = (state, close) => (
  //   <Menu
  //     anchorEl={state}
  //     transformOrigin={{ vertical: "top", horizontal: "center" }}
  //     open={Boolean(state)}
  //     onClose={close}
  //     keepMounted
  //     disableAutoFocusItem
  //   >
  //     <MenuItem onClick={close}>Last 7 days</MenuItem>
  //     <MenuItem onClick={close}>Last week</MenuItem>
  //     <MenuItem onClick={close}>Last 30 days</MenuItem>
  //   </Menu>
  // );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1.5}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="person"
                title="Umrah"
                count={totalUmrah.count}
                percentage={{
                  //   color: "success",
                  //   amount: "50%",
                  label: "Total number of Umrah's",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="analytics"
                title="Today's Umrah"
                count={todayUmrah.count}
                color="success"
                percentage={{
                  //   color: "warning",
                  //   amount: "23",
                  label: "Total number of Umrah's today",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="feedback"
                title="Feedbacks"
                count={totalfeedback.count}
                percentage={{
                  color: "",
                  amount: "",
                  label: "Total number of feedbacks",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="rate_review"
                title="New feedbacks"
                count={newfeedback.count}
                percentage={{
                  //   color: "success",
                  //   amount: "+1%",
                  label: "Total number of new feedbacks",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={6}>
              <DefaultLineChart
                title="Umrah"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="success" size="sm" badgeContent="Umrah Counts " />
                      <Select value={timeFrame} onChange={handleTimeFrameChange}>
                        <MenuItem value="hourly">Last 24 Hours</MenuItem>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </Select>
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="Line chart for Umrah counts" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={chartdata}
              />
            </Grid>
            {/* <Grid item xs={6} md={3} lg={3}>
              <DefaultStatisticsCard
                title=""
                count=""
                percentage={{
                  color: "secondary",
                  value: "",
                  label: (
                    <div style={{ paddingLeft: "20px" }}>
                      <h3>Shortest Durations</h3>
                      <ul>
                        {shortestDurations.map((duration, index) => (
                          <li key={`shortest-${index}`}>{duration}</li>
                        ))}
                      </ul>
                      <h3>Longest Durations</h3>
                      <ul>
                        {longestDurations.map((duration, index) => (
                          <li key={`longest-${index}`}>{duration}</li>
                        ))}
                      </ul>
                    </div>
                  ),
                }}
              />
            </Grid> */}
            {/* <Grid item sm={6} md={3} lg={3}>
              <DefaultStatisticsCard
                title="Average time between Umrahs for repeated Umrahs"
                count=""
                percentage={{
                  color: "secondary",
                  value: "",
                  label: (
                    <div style={{ paddingLeft: "20px" }}>
                      <ul>
                        {durationData.map((item, index) => (
                          <li key={index}>
                            {item.email}, Duration: {item.avg_time}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ),
                }}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Grid container spacing={5} mt={-4.5}>
                <Grid item sm={6}>
                  <DefaultStatisticsCard
                    title="Gender Percentage"
                    percentage={{
                      label: (
                        <div>
                          <span style={{ color: "#2983EB" }}>
                            <b>Male:</b> {genderpercentage.male_percentage}
                          </span>
                          <br />
                          <span style={{ color: "#E12C6C" }}>
                            <b>Female:</b> {genderpercentage.female_percentage}
                          </span>
                          <br />
                          <span>
                            <b>Not Selected: </b>
                            {genderpercentage.not_selected_percentage}
                          </span>
                          <br />
                          {/* <Button variant="text" color="primary" onClick={toggleShowMore}>
                            Show More
                          </Button> */}
                        </div>
                      ),
                    }}
                  />
                  {/* <Modal open={showMore} onClose={toggleShowMore}>
                    {renderModalContent()}
                  </Modal> */}
                </Grid>
                {/* <Grid item sm={6}>
                  <DefaultStatisticsCard
                    title="Average Umrah Duration"
                    count=""
                    percentage={{
                      color: "secondary",
                      value: "",
                      label: durationavg,
                    }}
                  />
                </Grid> */}
                <Grid item sm={6}>
                  <DefaultStatisticsCard
                    title="Top 5 Nationalities"
                    count=""
                    percentage={{
                      color: "",
                      value: "",
                      label: (
                        <div style={{ paddingLeft: "20px" }}>
                          <ol>
                            {nationalities.map((nationality) => (
                              <li key={nationality.id}>
                                {nationality.nationality} - {nationality.total}
                              </li>
                            ))}
                          </ol>
                        </div>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <DefaultStatisticsCard
                    title="Average Umrah Duration by gender"
                    count=""
                    percentage={{
                      color: "secondary",
                      value: "",
                      label: (
                        <div style={{ paddingLeft: "20px" }}>
                          <ol>
                            <li>Male: {durationavgbygender.Male || "-"}</li>
                            <li>Female: {durationavgbygender.Female || "-"}</li>
                            <li>Other: {durationavgbygender.gender_not_selected || "-"}</li>
                          </ol>
                        </div>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <DefaultStatisticsCard
                    title="Average Umrah Duration by nationality"
                    count=""
                    percentage={{
                      color: "secondary",
                      value: "",
                      label: (
                        <ul style={{ paddingLeft: "20px" }}>
                          {Object.keys(durationavgbynationality).map((nationality) => (
                            <li
                              key={`Nationality: ${nationality}`}
                            >{`${nationality}: ${durationavgbynationality[nationality]}`}</li>
                          ))}
                        </ul>
                      ),
                    }}
                  />
                </Grid>
                {/* <Grid item sm={6}>
                  <DefaultStatisticsCard
                    title="Repeated Pilgrims percentage"
                    count=""
                    percentage={{
                      color: "",
                      value: "",
                      label: repeatedUmrah,
                    }}
                    dropdown={{
                      action: openRevenueDropdown,
                      menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                      value: revenueDropdownValue,
                    }}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
