const actions = [
  {
    id: "BUTTON_PRESS",
    title: "Wait for button press",
    isDisabled: false,
  },
  {
    id: "ROTATE",
    title: "Rotate a certain degree",
    isDisabled: false,
  },
  {
    id: "PICK_UP",
    title: "Perform a package pick-up",
    isDisabled: true,
  },
  {
    id: "SEND_IMAGES",
    title: "Send images to the server",
    isDisabled: false,
  },
];

export default actions;
