/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
// Image
import bgImage from "assets/images/illustrations/mecca-img.jpg";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import axios from "axios";
// formik components
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
// Authentication layout components
import IllustrationLayout from "screens/Auth/components/IllustrationLayout";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
});

function ForgotPasswordScreen({ location }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  // get userLogin from the redux store with useSelector
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { from } = location.state || { from: { pathname: "/" } };
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const { email } = values;
      const forgotPassowrd = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          setLoading(true);

          const { data } = await axios.post(`/api/auth/users/reset_password/`, { email }, config);

          console.log(data);
          setLoading(false);
          setSuccess(true);
        } catch (err) {
          console.log(err);
          setError(true);
          // notify(
          //   err.response && err.response.data.message
          //     ? err.response.data.message
          //     : err.message,
          //   'danger'
          // );
          setLoading(false);
        }
      };

      forgotPassowrd();
    },
  });

  useEffect(() => {
    if (userInfo) {
      navigate(from);
    }
  }, [navigate, userInfo, from]);
  return (
    <IllustrationLayout
      title="Reset Password"
      description="You will receive an e-mail in maximum 60 seconds"
      illustration={bgImage}
    >
      {error && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="error" sx={{ width: "100%" }}>
              Something went wrong!
            </Alert>
          </MDBox>
        </Grid>
      )}
      {success && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="success" sx={{ width: "100%" }}>
              Reset email has been sent to you, check your inbox
            </Alert>
          </MDBox>
        </Grid>
      )}
      <FormikProvider value={formik}>
        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
          <MDBox mb={2}>
            <Field
              as={MDInput}
              type="email"
              label="Email"
              name="email"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <MDBox mt={0.75}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                <ErrorMessage name="email" />
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton
              disabled={loading}
              variant="gradient"
              color="info"
              size="large"
              fullWidth
              type="submit"
              sx={{ postion: "relative" }}
            >
              Reset
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "grey",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </MDButton>
          </MDBox>
          <MDBox mt={3} textAlign="center">
            <MDTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign in
            </MDTypography>
          </MDBox>
        </MDBox>
      </FormikProvider>
    </IllustrationLayout>
  );
}

ForgotPasswordScreen.propTypes = {
  location: PropTypes.string,
};

ForgotPasswordScreen.defaultProps = {
  location: "",
};

export default ForgotPasswordScreen;
