import React, { useEffect, useState } from "react";
// @mui material components
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDComponents/MDBox";
import MDTypography from "components/MDComponents/MDTypography";
import axios from "config/axios";
// Image
import bgImage from "assets/images/illustrations/mecca-img.jpg";
// Authentication layout components
import IllustrationLayout from "screens/Auth/components/IllustrationLayout";
import { useParams, Link } from "react-router-dom";

export default function ActivateAccountScreen() {
  const { uid, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const activate = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        setLoading(true);
        const { data } = await axios.post(`/api/auth/users/activation/`, { uid, token }, config);
        console.log(data);
        setLoading(false);
        setSuccess(true);
      } catch (err) {
        console.log(err);
        setError("Something went wrong!");
        setLoading(false);
      }
    };

    activate();
  }, []);
  return (
    <IllustrationLayout
      title="Activate Account"
      // description="Enter new password and"
      illustration={bgImage}
    >
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "grey",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      {error && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </MDBox>
        </Grid>
      )}
      {success && (
        <Grid container justifyContent="center">
          <MDBox m={0.75} mb={5}>
            <Alert severity="success" sx={{ width: "100%" }}>
              Your account has been activated
              <MDBox mt={3} textAlign="center">
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign in
                </MDTypography>
              </MDBox>
            </Alert>
          </MDBox>
        </Grid>
      )}
    </IllustrationLayout>
  );
}
