/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import { Select } from "@mui/material";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
// import MDBadgeDot from "components/MDComponents/MDBadgeDot";
// import MDButton from "components/MDComponents/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
// import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
// Data
// import defaultLineChartData from "screens/Dashboard/data/defaultLineChartData";
//  dates imports

// axios
// import axios from "axios";
import axiosinstance from "config/axios";

function Statistics() {
  //  Getting the top shortest and longest duration of an Umrah
  /* eslint-disable react/no-array-index-key */
  const [shortestDurations, setShortestDurations] = useState([]);
  const [longestDurations, setLongestDurations] = useState([]);

  useEffect(() => {
    // Fetch the top 5 shortest and longest durations from the API
    const fetchTopDurations = async () => {
      try {
        const response = await axiosinstance.get("api/top/umrah/duration/");
        setShortestDurations(response.data.shortest_umrah);
        setLongestDurations(response.data.longest_umrah);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTopDurations();
  }, []);

  //  getting the repeated umrah percentage
  const [repeatedUmrah, setRepeatedUmrah] = useState([]);

  useEffect(() => {
    async function fetchrepeatedUmrah() {
      try {
        const response = await axiosinstance.get("api/repeated/umrah/");
        setRepeatedUmrah(response.data.repeated_percentage);
        // console.log(response.data.repeated_percentage);
      } catch (error) {
        console.error(error);
      }
    }
    fetchrepeatedUmrah();
  }, []);

  // Getting the Average time between Umrahs for repeated Umrahs
  const [durationData, setDurationData] = useState([]);

  useEffect(() => {
    const fetchDurationData = async () => {
      try {
        const response = await axiosinstance.get("api/average/time/between/umrahs/");
        setDurationData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDurationData();
  }, []);

  //  Count Umrah of the current month

  const [currentmonthcount, setCurrentMonthCount] = useState([]);

  useEffect(() => {
    const fetchCurrentMonthCount = async () => {
      try {
        const response = await axiosinstance.get("api/count/umrah/current/month/");
        setCurrentMonthCount(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCurrentMonthCount();
  }, []);

  //  Count Umrah of the current week

  const [currentweekcount, setCurrentWeekCount] = useState([]);

  useEffect(() => {
    const fetchCurrentWeekCount = async () => {
      try {
        const response = await axiosinstance.get("api/count/umrah/current/week/");
        setCurrentWeekCount(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCurrentWeekCount();
  }, []);
  console.log(currentweekcount, " week counts");

  //  getting the average duration of all Umrahs
  const [durationavg, setdurationavg] = useState([]);

  useEffect(() => {
    async function fetchdurationavg() {
      try {
        const response = await axiosinstance.get("api/umrah/average/duration/");
        setdurationavg(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchdurationavg();
  }, []);
  //  Average umrah duration by month
  const [durationavgbymonth, setDurationAvgByMonth] = useState([]);

  useEffect(() => {
    async function fetchdurationavg() {
      try {
        const response = await axiosinstance.get("api/average/umrah/duration/by/month/");
        setDurationAvgByMonth(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchdurationavg();
  }, []);

  //  Busiest and Slowes month
  const [busiestslowest, setBusiestSlowest] = useState([]);

  useEffect(() => {
    async function fetchBusiestSlowest() {
      try {
        const response = await axiosinstance.get("api/busiest/and/slowest/months/");
        setBusiestSlowest(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBusiestSlowest();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1.5}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="person"
                title="Average Umrah"
                count={durationavg}
                percentage={{
                  //   color: "success",
                  //   amount: "50%",
                  label: "The Average duration for completed Umrahs",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="analytics"
                title="Repeated Percentage"
                count={repeatedUmrah}
                color="success"
                percentage={{
                  //   color: "warning",
                  //   amount: "23",
                  label: "Repeated Pilgrims Percentage",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="feedback"
                title="Current Month Count"
                count={currentmonthcount.count}
                percentage={{
                  color: "",
                  amount: "",
                  label: "Total number of Umrah's happened current month ",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="rate_review"
                title="Current Week Count"
                count={currentweekcount.count}
                percentage={{
                  //   color: "success",
                  //   amount: "+1%",
                  label: "Total number of Umrah's happened current week",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3} lg={3}>
              <DefaultStatisticsCard
                title="The top 10 shortest and longest umrah durations"
                count=""
                percentage={{
                  color: "secondary",
                  value: "",
                  label: (
                    <div style={{ paddingLeft: "20px" }}>
                      <h3>Shortest Durations</h3>
                      <ul>
                        {shortestDurations.map((duration, index) => (
                          <li key={`shortest-${index}`}>{duration}</li>
                        ))}
                      </ul>
                      <h3>Longest Durations</h3>
                      <ul>
                        {longestDurations.map((duration, index) => (
                          <li key={`longest-${index}`}>{duration}</li>
                        ))}
                      </ul>
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={6} md={3} lg={3}>
              <DefaultStatisticsCard
                title="Average time between Umrahs for repeated Umrahs"
                count=""
                percentage={{
                  color: "secondary",
                  value: "",
                  label: (
                    <div style={{ paddingLeft: "20px" }}>
                      <ul>
                        {durationData !== null ? (
                          durationData.map((item, index) => (
                            <li key={index}>
                              {item.email}, Duration: {item.avg_time}
                            </li>
                          ))
                        ) : (
                          <div>Loading ... </div>
                        )}
                      </ul>
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DefaultStatisticsCard
                title="Busiest and Slowest month for Umrah"
                count=""
                percentage={{
                  color: "secondary",
                  value: "",
                  label: (
                    <div style={{ paddingLeft: "20px" }}>
                      <h3>Busiest Month</h3>
                      <ul>
                        <li>{`Busiest Month: ${busiestslowest.busiest_month} Count: ${busiestslowest.busiest_month_count} Duration: ${busiestslowest.busiest_month_duration}`}</li>
                      </ul>
                      <h3>Longest Durations</h3>
                      <ul>
                        <li>{`Slowest Month: ${busiestslowest.slowest_month} Count: ${busiestslowest.slowest_month_count} Duration: ${busiestslowest.slowest_month_duration}`}</li>
                      </ul>
                    </div>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DefaultStatisticsCard
                title="Average duration by month "
                count=""
                percentage={{
                  color: "secondary",
                  value: "",
                  label: (
                    <div style={{ paddingLeft: "20px" }}>
                      <ul>
                        {Object.keys(durationavgbymonth).map((month) => (
                          <li
                            key={`Month: ${month}`}
                          >{`${month}: ${durationavgbymonth[month]}`}</li>
                        ))}
                      </ul>
                    </div>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Statistics;
