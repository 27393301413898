import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

function PrivateRoute({ roles }) {
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo: user } = userLogin;
  if (!user) {
    console.log("No user");
    // not logged in so redirect to login page with the return url
    return <Navigate to="/sign-in" />;
  }

  // check if route is restricted by role
  if (roles && roles.indexOf(user.role) === -1) {
    console.log(roles);
    // role not authorised so redirect to home page
    return <Navigate to="/" />;
  }

  console.log("Return outlet");
  return <Outlet />;
}

export default PrivateRoute;
