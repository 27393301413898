/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDBadgeDot from "components/MDComponents/MDBadgeDot";
import MDButton from "components/MDComponents/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
// import DefaultStatisticsCard from "components/Cards/StatisticsCards/DefaultStatisticsCard";
// import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import VerticalBarChart from "components/Charts/BarCharts/VerticalBarChart";
// import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
// Data
// import defaultLineChartData from "screens/Dashboard/data/defaultLineChartData";
// import { Pie } from "react-chartjs-2";
import PieChart from "components/Charts/PieChart";
// import Heatmap from "components/Charts/Heatmap";
// import { Chart } from "chart.js";
// import { Bar } from "react-chartjs-2";

// axios
// import axios from "axios";
import axiosinstance from "config/axios";

function Graphs() {
  // Line Chart data
  const [chartdata, setChartdata] = useState({
    labels: [],
    datasets: [
      {
        label: "Umrah Completed",
        color: "success",
        data: [],
      },
    ],
  });

  const [data, setData] = useState({});
  const [timeFrame, setTimeFrame] = useState("daily");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosinstance.get("api/umrah/data/");
        console.log("the data", response.data);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data[timeFrame]) {
      const keys = Object.keys(data[timeFrame]);
      const values = Object.values(data[timeFrame]);
      setChartdata({
        ...chartdata,
        labels: keys,
        datasets: [
          {
            label: "Completed",
            data: values,
          },
        ],
      });
    }
  }, [data, timeFrame]);

  const handleTimeFrameChange = (event) => {
    setTimeFrame(event.target.value);
  };

  // Pie Chart data
  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: {
      label: "Completed",
      backgroundColors: ["info", "secondary", "primary"],
      data: [],
    },
  });

  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosinstance.get("api/umrah/stats/");
        setPieData(response.data.gender_breakdown);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Update the pieChartData when the pieData changes
    setPieChartData((prevData) => ({
      ...prevData,
      labels: pieData.map((entry) => entry.gender),
      datasets: {
        ...prevData.datasets,
        data: pieData.map((entry) => entry.count),
      },
    }));
  }, [pieData]);

  console.log("PieChartData: ~ ", pieChartData);
  // Bar Chart data
  const [barData, setBarData] = useState({});
  const [dataType, setDataType] = useState("genderBreakdown");

  const [barchartdata, setBarChartData] = useState(() => {
    const initialChartData = {
      labels: [],
      datasets: [
        {
          label: "Completed",
          color: "success",
          data: [],
        },
      ],
    };
    return initialChartData;
  });

  useEffect(() => {
    const fetchBarData = async () => {
      try {
        const response = await axiosinstance.get("api/umrah/stats/bar/");
        console.log("the bar data", response.data);
        setBarData(response.data);
      } catch (error) {
        console.error("Error fetching bar data:", error);
      }
    };
    fetchBarData();
  }, []);

  useEffect(() => {
    if (barData[dataType]) {
      const keys = barData[dataType].map((item) => {
        if (item.gender) {
          return item.gender;
        }
        if (item.nationality) {
          return item.nationality;
        }
        return item.duration;
      });
      const values = barData[dataType].map((item) => item.count);
      setBarChartData((prevChartData) => ({
        ...prevChartData,
        labels: keys,
        datasets: [
          {
            label: "Completed",
            data: values,
          },
        ],
      }));
    }
  }, [barData, dataType]);
  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={6}>
              <VerticalBarChart
                title="Bar Chart"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot
                        color="success"
                        size="sm"
                        badgeContent="Bar Chart for demographics, nationality breakdown, and Umrah duration"
                      />
                      <Select value={dataType} onChange={handleDataTypeChange}>
                        <MenuItem value="genderBreakdown">Gender Breakdown</MenuItem>
                        <MenuItem value="nationalityBreakdown">Nationality Breakdown</MenuItem>
                        <MenuItem value="umrahDuration">Umrah Duration Breakdown</MenuItem>
                      </Select>
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip
                        title="Bar Chart for demographics, nationality breakdown, and Umrah duration"
                        placement="left"
                        arrow
                      >
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={barchartdata}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <PieChart
                title="Pie Chart"
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot
                        color="success"
                        size="sm"
                        badgeContent="Pie Chart for Gender Breakdown "
                      />
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="Pie chart for Gender Breakdown" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart={pieChartData}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <PieChart
                title="Scatter Plot soon..."
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="success" size="sm" badgeContent="Umrah Counts " />
                      <Select value={timeFrame} onChange={handleTimeFrameChange}>
                        <MenuItem value="hourly">Last 24 Hours</MenuItem>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </Select>
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="Line chart for Umrah counts" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart=""
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <PieChart
                title="Heatmap soon..."
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot color="success" size="sm" badgeContent="Umrah Counts " />
                      <Select value={timeFrame} onChange={handleTimeFrameChange}>
                        <MenuItem value="hourly">Last 24 Hours</MenuItem>
                        <MenuItem value="daily">Daily</MenuItem>
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                        <MenuItem value="yearly">Yearly</MenuItem>
                      </Select>
                    </MDBox>
                    <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                      <Tooltip title="Line chart for Umrah counts" placement="left" arrow>
                        <MDButton
                          variant="outlined"
                          color="secondary"
                          size="small"
                          circular
                          iconOnly
                        >
                          <Icon>priority_high</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                }
                chart=""
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Graphs;
