// @mui material components
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import "@pixi/graphics-extras";
import brandWhite from "assets/images/Logo-In-White-II.png";
// Material Dashboard 2 React themes
import theme from "assets/theme";
import Sidenav from "components/Sidenav";
import {
  USER_ROLE_ADMIN,
  // USER_ROLE_MANAGER,
  USER_ROLE_COFFEE_OPERATOR,
} from "constants/userConstants";

// import routes from "routes";
import { useMaterialUIController } from "context";
// react-router components
import { Navigate, Route, Routes } from "react-router-dom";
// import AppRoute from "routes/appRoute";
// import AuthRoute from "routes/authRoute";
import PrivateRoute from "routes/privateRoute";
import sideNavRoutes from "sideNav.routes";
import coffeeSideNavRoutes from "sideNavCoffee.routes";
import DashboardScreen from "screens/Dashboard";
import GraphsScreen from "screens/Statistics";
import StatisticsScreen from "screens/Statistics/extra_statistics";
// Users screens
import UserListScreen from "screens/Umrah/umrah-list";
import UserAddScreen from "screens/Umrah/new-user";
import UserEditScreen from "screens/Umrah/edit-user";
import UserProfileScreen from "screens/Umrah/user-profile";
// // Robots screens
// import RobotListScreen from "screens/Robots/robot-list";
// import RobotAddScreen from "screens/Robots/new-robot";
// import RobotEditScreen from "screens/Robots/edit-robot";
// import RobotControlScreen from "screens/Robots/control";
// Missions screens
import FeedbackListScreen from "screens/Feedbacks/feedback-list";
import MissionAddScreen from "screens/Feedbacks/new-mission";
import MissionEditScreen from "screens/Feedbacks/edit-mission";
// Maps screens
// import MapListScreen from "screens/Maps/map-list";
// import MapAddScreen from "screens/Maps/new-map";
// import MapEditScreen from "screens/Maps/edit-map";
import SignInScreen from "screens/Auth/sign-in";
// Auth screens
import ForgotPasswordScreen from "screens/Auth/forgot-password";
import ResetPasswordScreen from "screens/Auth/reset-password";
import EditPasswordScreen from "screens/Umrah/update-user-password";
import ActivateAccountScreen from "screens/Auth/activate-account";
import { useSelector } from "react-redux";
// import NewProduct from "screens/ecommerce/products/new-product";
// import EditProduct from "screens/ecommerce/products/edit-product";
// import ProductPage from "screens/ecommerce/products/product-page";
// import OrderList from "screens/ecommerce/orders/order-list";
// import OrderDetails from "screens/ecommerce/orders/order-details";
// import ProductList from "screens/ecommerce/products/product-list";

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const sidenavColor = "dark";

  const [controller] = useMaterialUIController();
  const { layout } = controller;
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    // if (miniSidenav && !onMouseEnter) {
    //   setMiniSidenav(dispatch, false);
    //   setOnMouseEnter(true);
    // }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    // if (onMouseEnter) {
    //   setMiniSidenav(dispatch, true);
    //   setOnMouseEnter(false);
    // }
  };

  // const getRoutes = (allRoutes) =>
  //   allRoutes
  //     .find((route) => route.layout === "dashboard")
  //     .map((route) => <AppRoute path={route.path} component={route.component} />);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brand={brandWhite}
          brandName=""
          routes={userInfo && userInfo.is_coffee_operator ? coffeeSideNavRoutes : sideNavRoutes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        <Route path="/sign-in" element={<SignInScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/reset-password/:uid/:token" element={<ResetPasswordScreen />} />
        <Route path="/activate-account/:uid/:token" element={<ActivateAccountScreen />} />

        {/* All users can access routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<DashboardScreen />} />
          <Route path="/profile" element={<UserProfileScreen />} />
          <Route path="/edit-password" element={<EditPasswordScreen />} />
          <Route path="/feedbacks" element={<FeedbackListScreen />} />
          <Route path="/missions/create" element={<MissionAddScreen />} />
          <Route path="/missions/:id/edit" element={<MissionEditScreen />} />
          <Route path="/graphs" element={<GraphsScreen />} />
          <Route path="/statistics" element={<StatisticsScreen />} />
          {/* <Route path="/robots/:id/control" element={<RobotControlScreen />} /> */}
        </Route>

        {/* Only admin routes */}
        <Route roles={[USER_ROLE_ADMIN]} element={<PrivateRoute />}>
          <Route path="/umrah" element={<UserListScreen />} />
          <Route path="/users/create" element={<UserAddScreen />} />
          <Route path="/users/:id/edit" element={<UserEditScreen />} />
          {/* <Route path="/maps/" element={<MapListScreen />} />
          <Route path="/maps/create" element={<MapAddScreen />} />
          <Route path="/maps/:id/edit" element={<MapEditScreen />} /> */}
        </Route>

        {/* admin and manager routes */}
        {/* <Route roles={[USER_ROLE_ADMIN, USER_ROLE_MANAGER]} element={<PrivateRoute />}>
          <Route path="/robots/robot-list" element={<RobotListScreen />} />
          <Route path="/robots/create" element={<RobotAddScreen />} />
          <Route path="/robots/:id/edit" element={<RobotEditScreen />} />
        </Route> */}

        <Route roles={[USER_ROLE_COFFEE_OPERATOR]} element={<PrivateRoute />}>
          {/* <Route path="/robots/robot-list" element={<RobotListScreen />} /> */}
          {/* <Route path="/robots/create" element={<RobotAddScreen />} />
          <Route path="/robots/:id/edit" element={<RobotEditScreen />} /> */}
          {/* <Route path="/ecommerce/products/product-list" element={<ProductList />} />
          <Route path="/ecommerce/products/new-product" element={<NewProduct />} />
          <Route path="/ecommerce/products/:id/edit" element={<EditProduct />} />
          <Route path="/ecommerce/products/product-page" element={<ProductPage />} />
          <Route path="/ecommerce/orders/order-list" element={<OrderList />} />
          <Route path="/ecommerce/orders/:id/details" element={<OrderDetails />} /> */}
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
