/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// @mui material components
import { useState, forwardRef } from "react";
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";

// eslint-disable-next-line prefer-arrow-callback
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ActionsCell({ value }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
      {value.canDelete && (
        <MDButton variant="text" color="error" onClick={handleClickOpen}>
          <Icon>delete</Icon>
        </MDButton>
      )}
      <MDButton
        variant="text"
        color={darkMode ? "white" : "dark"}
        onClick={(e) => {
          e.preventDefault();
          value.handleEdit(value.email);
        }}
      >
        <Icon>mail</Icon>
      </MDButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Are you sure you want to delete this ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>cancel</MDButton>
          <MDButton
            onClick={() => {
              value.handleDelete(value.id);
              handleClose();
            }}
          >
            delete
          </MDButton>
        </DialogActions>
      </Dialog>
    </MDBox>
  );
}

// Typechecking props for the ActionsCell
ActionsCell.propTypes = {
  value: PropTypes.PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    canDelete: PropTypes.bool.isRequired,
  }),
};
ActionsCell.defaultProps = {
  value: {
    id: "",
    email: "",
    handleEdit: () => console.log("d"),
    handleDelete: () => console.log("d"),
    canDelete: true,
  },
};
export default ActionsCell;
