/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// @mui icons
import Icon from "@mui/material/Icon";
import DashboardScreen from "screens/Dashboard";
// import MapList from "screens/Maps/map-list";
// import MapsAddScreen from "screens/Maps/new-map";
import FeedbacksListScreen from "screens/Feedbacks/feedback-list";
// import MissionsAddScreen from "screens/Missions/new-mission";
// import RobotsAddScreen from "screens/Robots/new-robot";
// import RobotList from "screens/Robots/robot-list";
// import UsersAddScreen from "screens/Users/new-user";
import UsersListScreen from "screens/Umrah/umrah-list";
// import Overview from "screens/Users/user-profile";
import GraphsScreen from "screens/Statistics";
import StatisticsScreen from "screens/Statistics/extra_statistics";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   component: <Overview />,
  //   icon: <Icon fontSize="medium">person</Icon>,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/",
    component: <DashboardScreen />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Umrah",
    key: "users",
    icon: <Icon fontSize="medium">list_alt</Icon>,
    collapse: [
      {
        name: "Manage Umrah's",
        key: "manage_users",
        route: "/umrah",
        component: <UsersListScreen />,
      },
      // {
      //   name: "Add User",
      //   key: "add_user",
      //   route: "/users/create",
      //   component: <UsersAddScreen />,
      // },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "maps",
  //   key: "maps",
  //   icon: <Icon fontSize="medium">maps</Icon>,
  //   collapse: [
  // {
  //   name: "Add Map",
  //   key: "add_map",
  //   route: "/maps/create",
  //   component: <MapsAddScreen />,
  // },
  //     {
  //       name: "Manage maps",
  //       key: "manage_maps",
  //       route: "/maps",
  //       component: <MapList />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Robots",
  //   key: "robots",
  //   icon: <Icon fontSize="medium">precision_manufacturing_outlined</Icon>,
  //   collapse: [
  //     {
  //       name: "Add Robot",
  //       key: "add_robot",
  //       route: "/robots/create",
  //       component: <RobotsAddScreen />,
  //     },
  //     {
  //       name: "Manage Robots",
  //       key: "manage_robots",
  //       route: "/robots/robot-list",
  //       component: <RobotList />,
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Feedbacks",
    key: "missions",
    icon: <Icon fontSize="medium">feedback</Icon>,
    collapse: [
      // {
      //   name: "Add Mission",
      //   key: "add_mission",
      //   route: "/missions/create",
      //   component: <MissionsAddScreen />,
      // },
      {
        name: "Manage Feedbacks",
        key: "manage_missions",
        route: "/feedbacks",
        component: <FeedbacksListScreen />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Statistics",
    key: "graphs",
    icon: <Icon fontSize="medium">maps</Icon>,
    collapse: [
      {
        name: "Analytics Graphs",
        key: "view_graphs",
        route: "/graphs",
        component: <GraphsScreen />,
      },
      {
        name: "Additinal Statistics",
        key: "view_graphs",
        route: "/statistics",
        component: <StatisticsScreen />,
      },
    ],
  },
];

export default routes;
