import axios from "axios";
import history from "./history";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    let userInfo = localStorage.getItem("userInfo");
    userInfo = JSON.parse(userInfo);
    const token = userInfo.access;

    if (token) {
      // eslint-disable-next-line prefer-template
      config.headers.Authorization = "Bearer " + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/users/login/" && err.response) {
      // Access Token was expired
      // eslint-disable-next-line no-underscore-dangle
      if (err.response.status === 401 && !originalConfig._retry) {
        try {
          localStorage.removeItem("userInfo");
          history.go("/sign-in");
        } catch (error) {
          console.log("error in log out", error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
