/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import DefaultImage from "assets/images/default-avatar.png";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
// import MDButton from "components/MDComponents/MDButton";
import axiosInstance from "config/axios";
import Footer from "components/Footer";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import ActionsCell from "./components/ActionsCell";
import CustomerCell from "./components/CustomerCell";
import DefaultCell from "./components/DefaultCell";
// Cells
import IdCell from "./components/IdCell";
// import StatusCell from "./components/StatusCell";

/* eslint-disable react/prop-types */
const columns = [
  { Header: "ID", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
  {
    Header: "User",
    accessor: "user",
    Cell: ({ value: [name, data] }) => (
      <CustomerCell image={data.image || DefaultImage} color={data.color || "dark"} name={name} />
    ),
  },
  { Header: "Gender", accessor: "gender", Cell: ({ value }) => <DefaultCell value={value} /> },
  // { Header: "Email", accessor: "email", Cell: ({ value }) => <DefaultCell value={value} /> },
  {
    Header: "Nationality",
    accessor: "nationality",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Birth Year",
    accessor: "birthYear",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Umrah Start Time",
    accessor: "omrah_start_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Umrah end Time",
    accessor: "omrah_end_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Ihram End Time",
    accessor: "ihram_end_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Talbiyah end Time",
    accessor: "talbiyah_end_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Tawaf end Time",
    accessor: "tawaf_end_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Salah Behind Maqam end Time",
    accessor: "salah_behind_maqam_end_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Sai end Time",
    accessor: "sai_end_time",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "Duration",
    accessor: "duration",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  // {
  //   Header: "Status",
  //   accessor: "status",
  //   Cell: ({ value }) => {
  //     let status;

  //     if (value === "active") {
  //       status = <StatusCell icon="done" color="success" status="Active" />;
  //     } else if (value === "pending") {
  //       status = <StatusCell icon="pending" color="dark" status="Pending" />;
  //     } else {
  //       status = <StatusCell icon="close" color="error" status="Canceled" />;
  //     }

  //     return status;
  //   },
  // },
  {
    Header: "Actions",
    accessor: "action",
    Cell: ({ value }) => <ActionsCell value={value} />,
  },
];
/* eslint-enable react/prop-types */

function UserList() {
  // const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [table, setTable] = useState({
    columns,
    rows: [],
  });
  const handleDelete = async (id) => {
    const { res } = await axiosInstance.delete(`api/list_omrah/${id}/`);
    console.log(res);
    setTable((prevTable) => ({
      ...table,
      rows: prevTable.rows.filter((user) => user.id !== id),
    }));
  };
  // const handleEdit = async (id) => {
  //   console.log(id);
  //   navigate(`/users/${id}/edit`);
  // };
  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await axiosInstance.get("api/list_omrah/");
        console.log("data: ", data);
        setTable({
          ...table,
          rows: data.results
            ? data.results.map((prop) => ({
                id: prop.id,
                user: [prop.email, { image: DefaultImage }],
                // email: prop.email,
                gender: prop.gender,
                birthYear: prop.birthYear,
                nationality: prop.nationality,
                omrah_start_time: prop.omrah_start_time,
                omrah_end_time: prop.omrah_end_time,
                ihram_end_time: prop.ihram_end_time,
                talbiyah_end_time: prop.talbiyah_end_time,
                tawaf_end_time: prop.tawaf_end_time,
                salah_behind_maqam_end_time: prop.salah_behind_maqam_end_time,
                sai_end_time: prop.sai_end_time,
                duration: prop.duration,
                // status: prop.is_active ? "active" : "pending",
                action: {
                  value: "editDelete",
                  id: prop.id,
                  handleDelete,
                  // handleEdit,
                  canDelete: prop.id !== userInfo.id,
                },
              }))
            : [],
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    };

    getUsers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {/* <MDButton variant="gradient" color="info" onClick={() => navigate("/users/create")}>
            new user
          </MDButton> */}
        </MDBox>
        <Card>
          <DataTable table={table} entriesPerPage={10} canSearch />
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default UserList;
