/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import AdapterLuxon from "@mui/lab/AdapterLuxon";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import axios from "axios";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDComponents/MDBox";
import MDButton from "components/MDComponents/MDButton";
import MDInput from "components/MDComponents/MDInput";
import MDTypography from "components/MDComponents/MDTypography";
import Footer from "components/Footer";
// import DateTimeValidation from "./components/DateTimeField";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
// formik components
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import actions from "screens/Missions/actions";
import { uid } from "uid";
import * as yup from "yup";
import actions from "../actions";

const validationSchema = yup.object({
  order_number: yup
    .number("This field must be a number")
    .positive()
    .integer()
    .required("This field is required"),
  map: yup.string().required("Must select a map"),
  robot: yup.string().required("Must select a robot"),
});

function EditMissionScreen() {
  const navigate = useNavigate();
  const { id: missionId } = useParams();

  const [maps, setMaps] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedMap, setSelectedMap] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const formik = useFormik({
    initialValues: {
      order_number: "",
      priority: "NORMAL",
      map: "",
      robot: "",
      execution_time: DateTime.now(),
    },

    validationSchema,
    onSubmit: (values) => {
      console.log("here");
      console.log(values);
      console.log(waypoints);
      /* eslint-disable  */
      const { execution_time, map, order_number, priority, robot } = values;
      // const { order_number: orderNumber, priority, map, robot } = values;
      if (!waypoints.every(({ location, action }) => location.id && action.id)) {
        setError("Some waypoints are empty");
        return;
      }
      let waypointsToSend = waypoints.map((waypoint) => ({
        location: waypoint.location.id,
        action: waypoint.action.id,
      }));
      console.log(values, waypoints);

      const editMission = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          setLoading(true);

          const { data } = await axios.put(
            `/ missions/${missionId}`,
            {
              execution_time: execution_time.toString(),
              map,
              order_number,
              priority,
              robot,
              waypoints: waypointsToSend,
            },
            config
          );

          console.log(data);
          setLoading(false);
          setSuccess(true);
          navigate("/missions");
        } catch (err) {
          console.log(err);
          setError("Something went wrong!");
          // notify(
          //   err.response && err.response.data.message
          //     ? err.response.data.message
          //     : err.message,
          //   'danger'
          // );
          setLoading(false);
          console.log(loading, success);
        }
      };

      editMission();
      /* eslint-enable  */
    },
  });

  useEffect(() => {
    const getMission = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.access}`,
          },
        };

        const { data } = await axios.get(`/ missions/${missionId}`, config);
        console.log(data.waypoints);
        formik.setFieldValue("order_number", data.order_number);
        formik.setFieldValue("priority", data.priority);
        formik.setFieldValue("map", data.map.id);
        formik.setFieldValue("robot", data.robot.id);
        formik.setFieldValue("execution_time", DateTime.fromISO(data.execution_time));
        setSelectedMap(data.map);
        setWaypoints(
          data.waypoints.map(({ id, station, action }) => {
            console.log(action);
            return {
              id,
              location: station,
              action: actions.find((item) => action === item.id),
            };
          })
        );
      } catch (err) {
        console.log("Error: ", err);
      }
    };

    getMission();
  }, [maps]);

  useEffect(() => {
    const getMaps = async () => {
      try {
        const { data } = await axios.get("/ maps/listCreate/");
        console.log("data: ", data);
        setMaps(data);
      } catch (err) {
        console.log(err);
      }
    };

    getMaps();
  }, []);

  const onChangeWaypoint = (type, waypointId, value) => {
    console.log(type, waypointId, value);
    // const [selectName, id] = action.name.split('_');
    setWaypoints((prevWaypoints) =>
      prevWaypoints.map((waypoint) => {
        if (waypoint.id === waypointId) {
          if (type === "location") {
            return {
              ...waypoint,
              location: value,
            };
          }
          if (type === "action") {
            return {
              ...waypoint,
              action: value,
            };
          }
        }
        return waypoint;
      })
    );
  };

  const addWaypointHandler = () => {
    setWaypoints((prevWaypoints) => [...prevWaypoints, { id: uid(), location: {}, action: {} }]);
  };

  const deleteWaypointHandler = (id) => {
    console.log(id);
    if (waypoints.length === 1) {
      setError("Mission must contain one waypoint at least");
      return;
    }
    setWaypoints(waypoints.filter((waypoint) => waypoint.id !== id));
  };

  useEffect(() => {
    console.log(selectedMap);
  }, [selectedMap]);

  useEffect(() => {
    console.log(waypoints);
  }, [waypoints]);

  useEffect(() => {
    setError("");
  }, [waypoints, formik.values]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDTypography variant="h4" fontWeight="medium" align="left">
                Edit Mission
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={3}>
                {error && (
                  <Grid container justifyContent="center">
                    <MDBox m={0.75} mb={5}>
                      <Alert severity="error" sx={{ width: "100%" }}>
                        {error}
                      </Alert>
                    </MDBox>
                  </Grid>
                )}
                <FormikProvider value={formik}>
                  <MDBox mt={1} component="form" role="form" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={12} sm={5}>
                        <Field
                          fullWidth
                          as={MDInput}
                          type="text"
                          label="Order Number"
                          name="order_number"
                          value={formik.values.order_number}
                          onChange={formik.handleChange}
                          error={formik.touched.order_number && Boolean(formik.errors.order_number)}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="order_number" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      {/* <Grid item xs={12} sm={4}>
                        <Field
                          fullWidth
                          as={MDInput}
                          type="text"
                          label="Priority"
                          name="priority"
                          value={formik.values.priority}
                          onChange={formik.handleChange}
                          error={formik.touched.priority && Boolean(formik.errors.priority)}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="priority" />
                          </MDTypography>
                        </MDBox>
                      </Grid> */}
                      <Grid item xs={12} sm={5}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                          <DateTimePicker
                            renderInput={(props) => <MDInput {...props} fullWidth />}
                            name="execution_time"
                            label="Execution Date & Time"
                            value={formik.values.execution_time}
                            onChange={(newValue) => {
                              formik.setFieldValue("execution_time", newValue);
                            }}
                            minDateTime={DateTime.now()}
                          />
                        </LocalizationProvider>
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="execution_time" />
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      {maps.length && (
                        <Grid item xs={12} sm={5}>
                          <Autocomplete
                            options={maps}
                            getOptionLabel={(option) => option.name || ""}
                            value={
                              formik.values.map
                                ? maps.find((map) => map.id === formik.values.map)
                                : ""
                            }
                            renderInput={(params) => (
                              <MDInput
                                label="Choose Map"
                                {...params}
                                variant="outlined"
                                name="map"
                                error={formik.touched.map && Boolean(formik.errors.map)}
                              />
                            )}
                            onChange={(event, newValue) => {
                              formik.setFieldValue("map", newValue.id);

                              setSelectedMap(newValue);
                            }}
                            onBlur={() => formik.setTouched({ map: true })}
                          />
                          <MDBox mt={0.75}>
                            <MDTypography
                              component="div"
                              variant="caption"
                              color="error"
                              fontWeight="regular"
                            >
                              <ErrorMessage name="map" />
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      )}

                      {selectedMap && selectedMap.robots && (
                        <Grid item xs={12} sm={5}>
                          <>
                            <Autocomplete
                              disabled={!selectedMap}
                              options={selectedMap.robots}
                              getOptionLabel={(option) => option.name || ""}
                              renderInput={(params) => (
                                <MDInput
                                  label="Choose Robot"
                                  {...params}
                                  variant="outlined"
                                  name="robot"
                                  error={formik.touched.robot && Boolean(formik.errors.robot)}
                                />
                              )}
                              onChange={(event, newValue) => {
                                formik.setFieldValue("robot", newValue.id);
                              }}
                              value={
                                formik.values.robot
                                  ? selectedMap.robots.find(
                                      (robot) => robot.id === formik.values.robot
                                    )
                                  : ""
                              }
                              onBlur={() => formik.setTouched({ robot: true })}
                            />
                            <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                <ErrorMessage name="robot" />
                              </MDTypography>
                            </MDBox>
                          </>
                        </Grid>
                      )}
                      <Grid item xs={12} lg={12}>
                        <Grid container spacing={3} justifyContent="center">
                          <Grid item xs={12} lg={12}>
                            <MDTypography variant="h4" fontWeight="medium" align="center">
                              Waypoints
                            </MDTypography>
                          </Grid>

                          {selectedMap &&
                            waypoints.length &&
                            waypoints.map((waypoint) => (
                              <Grid item xs={12} lg={12} key={waypoint.id}>
                                <Grid container spacing={3} justifyContent="center">
                                  <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                      options={selectedMap.stations}
                                      getOptionLabel={(option) => option.name || ""}
                                      value={
                                        waypoint.location.id
                                          ? selectedMap.stations.find(
                                              (station) => waypoint.location.id === station.id
                                            )
                                          : ""
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option && value && option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <MDInput
                                          label="Choose Location"
                                          {...params}
                                          variant="outlined"
                                          name={`location_${waypoint.id}`}
                                        />
                                      )}
                                      onChange={(event, newVal) =>
                                        onChangeWaypoint("location", waypoint.id, newVal)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                      options={actions}
                                      getOptionLabel={(option) => option.title || ""}
                                      value={
                                        waypoint.action
                                          ? actions.find((action) => waypoint.action === action)
                                          : ""
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option && value && option.id === value.id
                                      }
                                      renderInput={(params) => (
                                        <MDInput
                                          label="Choose Action"
                                          {...params}
                                          variant="outlined"
                                          name={`action_${waypoint.id}`}
                                        />
                                      )}
                                      onChange={(event, newVal) =>
                                        onChangeWaypoint("action", waypoint.id, newVal)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} lg={2} align="center">
                                    <MDBox pt={2}>
                                      <Icon
                                        fontSize="medium"
                                        onClick={() => {
                                          console.log(waypoint.id);
                                          deleteWaypointHandler(waypoint.id);
                                        }}
                                      >
                                        remove
                                      </Icon>
                                      <Icon
                                        fontSize="medium"
                                        sx={{ marginLeft: 1 }}
                                        onClick={addWaypointHandler}
                                      >
                                        add
                                      </Icon>
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <MDBox display="flex" justifyContent="flex-end">
                          <MDButton variant="gradient" color="info" type="submit">
                            save
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </FormikProvider>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditMissionScreen;
