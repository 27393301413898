/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// @mui icons
import Icon from "@mui/material/Icon";
// import OrderDetails from "screens/ecommerce/orders/order-details";
// import OrderList from "screens/ecommerce/orders/order-list";
// import NewProduct from "screens/ecommerce/products/new-product";
// import ProductList from "screens/ecommerce/products/product-list";
// import ProductPage from "screens/ecommerce/products/product-page";
import UsersAddScreen from "screens/Umrah/new-user";
import UsersListScreen from "screens/Umrah/umrah-list";
// import RobotsAddScreen from "screens/Robots/new-robot";
// import RobotList from "screens/Robots/robot-list";

// import Overview from "screens/Users/user-profile";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   component: <Overview />,
  //   icon: <Icon fontSize="medium">person</Icon>,
  //   noCollapse: true,
  // },

  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "Manage Users",
        key: "manage_users",
        route: "/umrah",
        component: <UsersListScreen />,
      },
      {
        name: "Add User",
        key: "add_user",
        route: "/users/create",
        component: <UsersAddScreen />,
      },
    ],
  },
  // { type: "divider", key: "divider-0" },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "Products",
  //           key: "product-list",
  //           route: "/ecommerce/products/product-list",
  //           component: <ProductList />,
  //         },
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         // {
  //         //   name: "Order Details",
  //         //   key: "order-details",
  //         //   route: "/ecommerce/orders/order-details",
  //         //   component: <OrderDetails />,
  //         // },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Robots",
  //   key: "Robots",
  //   icon: <Icon fontSize="medium">precision_manufacturing_outlined</Icon>,
  //   collapse: [
  //     // {
  //     //   name: "Add Robot",
  //     //   key: "add_robot",
  //     //   route: "/robots/create",
  //     //   component: <RobotsAddScreen />,
  //     // },
  //     {
  //       name: "Manage Robots",
  //       key: "manage_robots",
  //       route: "/robots/robot-list",
  //       component: <RobotList />,
  //     },
  //   ],
  // },
];

export default routes;
